import request from '@/utils/request'

export default {
  // 获取用户信息
  getUserDetailVo(token){
    return request({
      url: '/user/getUserDetailVo',
      method: 'get',
      headers: {
        token: token
      }
    })
  },
  // 获取短信验证码
  postSms(phone,key,time,md5,token){
    return request({
      url: '/user/sms',
      method: 'post',
      headers: {
        token: token ? token : '',
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: {
        phone: phone
      }
    })
  },
  // 绑定手机号码
  postBindPhone(data,token){
    return request({
      url: '/user/bindPhone',
      method: 'post',
      headers: {
        token: token
      },
      data: data
    })
  },
  // 修改用户头像
  updateHeadImg(headImg,token){
    return request({
      url: '/user/updateHeadImg',
      method: 'post',
      headers: {
        token: token
      },
      data: {
        headImg: headImg
      }
    })
  },
  // 修改昵称
  updateNickName(nickname,token){
    return request({
      url: '/user/updateNickName',
      method: 'post',
      headers: {
        token: token
      },
      data: {
        nickname: nickname
      }
    })
  },
  // 查询 我的消费订单
  postUserOrder(data,token){
    return request({
      url: '/user/order',
      method: 'post',
      headers: {
        token: token
      },
      data: data
    })
  },
  // 查询 充值记录
  postWalletLog(data,token){
    return request({
      url: '/user/wallet/log',
      method: 'post',
      headers: {
        token: token
      },
      data: data
    })
  },
  // 获取Vip下载次数信息
  getVipDownloadInfo(token){
    return request({
      url: '/user/getVipDownloadInfo',
      method: 'get',
      headers: {
        token: token
      }
    })
  },
  // 手机号码验证码登录
  postLoginByPhone(data,key,time,md5){
    return request({
      url: '/user/loginByPhone',
      method: 'post',
      headers: {
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: data
    })
  }
}
