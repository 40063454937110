import request from '@/utils/request'

export default {
  // 创建充值升级订单
  createOrder(token,channelCode){
    return request({
      url: '/promotion/createOrder',
      method: 'post',
      headers: {
        token: token
      },
      data: {channelCode: channelCode}
    })
  },
  // 获取登录二维码
  getLoginUrl(){
    return request({
      url: '/promotion/getLoginUrl',
      method: 'get'
    })
  },
  // 获取级别信息
  getLevelList(token){
    return request({
      url: '/promotion/level/list',
      method: 'get',
      headers: {
        token: token
      }
    })
  },
  // 获取级别规则信息
  getLevelRule(){
    return request({
      url: '/promotion/level/rule',
      method: 'get'
    })
  },
  // 查询充值记录
  postPayPage(token,data){
    return request({
      url: '/promotion/pay/page',
      method: 'post',
      headers: {
        token: token
      },
      data: data
    })
  },
  // 查询是否登录
  queryLogin(key){
    return request({
      url: '/promotion/queryLogin',
      method: 'post',
      data: {key: key}
    })
  },
  // 查询推广订单
  postShareOrder(token,data){
    return request({
      url: '/promotion/share/order',
      method: 'post',
      headers: {
        token: token
      },
      data: data
    })
  },
  // 获取分享链接
  shareLink(token,channelCode,key,time,md5){
    return request({
      url: '/promotion/shareLink',
      method: 'post',
      data: {channelCode: channelCode},
      headers: {
        token: token,
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      }
    })
  },
  // 获取用户信息
  getUserInfo(token){
    return request({
      url: '/promotion/userinfo',
      method: 'get',
      headers: {
        token: token
      }
    })
  },
  // 提现申请
  postWithdraw(token,data){
    return request({
      url: '/promotion/withdraw',
      method: 'post',
      headers: {
        token: token
      },
      data: data
    })
  },
  // 查询提现申请
  postWithdrawPage(token,data){
    return request({
      url: '/promotion/withdraw/page',
      method: 'post',
      headers: {
        token: token
      },
      data: data
    })
  },
  // 上传图片 获取url
  shareImage(file,token,key,time,md5){
    return request({
      url: '/promotion/shareImage',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        token: token,
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: file
    })
  },
}
